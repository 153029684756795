import { getOptionIcon } from "utils/projectUtils.";

export const DETAILS_TABS = {
  general: "general",
  platforms: "platforms",
  layouts: "layouts",
  performance: "performance",
  availability: "availability",
  security: "security",
};

export const MODAL_TYPES = {
  create_edit: "create/edit",
  delete: "delete",
};

export enum ENTITY_TYPES {
  screen = "screen",
  group = "group",
  element = "element",
}

export enum KINDS {
  element = "element",
  subtask = "subtask",
  bug = "bug",
}

export const PRIORITIES = {
  lowest: "lowest",
  low: "low",
  medium: "medium",
  high: "high",
  highest: "highest",
};

export const ELEMENT_TYPES = {
  input: "input",
  button: "button",
  checkbox: "checkbox",
  datepicker: "datetime_picker",
  dropdown: "dropdown",
  fileUploader: "file_uploader",
  htmlIcon: "html_icon",
  image: "image",
  link: "link",
  map: "map",
  multilineInput: "multiline_input",
  pictureUploader: "picture_uploader",
  radioButton: "radio",
  searchBox: "search",
  shape: "shape",
  sliderInput: "slider_input",
  text: "text",
  video: "video",
};

export const ORIENTATION = {
  horizontal: "horizontal",
  vertical: "vertical",
};

export const TABS = {
  project: "project",
  board: "board",
  backlog: "backlog",
};

export const HEADER_HEIGHT = 112;
export const HEADER_HEIGHT_SMALL = 52;
export const MIN_ZOOM = 0.4;
export const MAX_ZOOM = 3.6;
export const ZOOM_DATA = [25, 50, 75, 100];

export const PrioritySelectData = [
  {
    icon: getOptionIcon(PRIORITIES.highest),
    label: "Highest",
    value: PRIORITIES.highest,
  },

  {
    icon: getOptionIcon(PRIORITIES.high),
    label: "High",
    value: PRIORITIES.high,
  },
  {
    icon: getOptionIcon(PRIORITIES.medium),
    label: "Medium",
    value: PRIORITIES.medium,
  },
  {
    icon: getOptionIcon(PRIORITIES.low),
    label: "Low",
    value: PRIORITIES.low,
  },
  {
    icon: getOptionIcon(PRIORITIES.lowest),
    label: "Lowest",
    value: PRIORITIES.lowest,
  },
];

export const COMMON_RESOLUTIONS = {
  desktop: `1920x1080\n1376x768\n1536x864\n1440x900\n1280x1024`,
  tablet: `768x1024\n810x1080\n800x1280\n834x1194\n1024x1366`,
  mobile: `375x812\n390x844\n393x852\n360x800\n428x926`,
};

export const BROWSERS = ["Chrome", "Opera", "Firefox", "Safari", "Edge"];

export const ACCESSIBILITY_VALUES = [
  "WCAG 2.1 Guidelines",
  "Screen Reader",
  "Keyboard Accessibility",
  "Color Blindness Modes",
  "Scalable Interface",
];

export const getEmptyNonTech = (id: number) => {
  const formData = new FormData();
  formData.append("project", JSON.stringify(id));
  formData.append("general_information", JSON.stringify({ project_details: "" }));
  formData.append("platforms", JSON.stringify([]));
  formData.append(
    "responsive_layouts",
    JSON.stringify({
      desktop: { resolution: "", notes: "", order: 1 },
      tablet: { resolution: "", notes: "", order: 2 },
      mobile: { resolution: "", notes: "", order: 3 },
    }),
  );
  formData.append(
    "availability_accessibility",
    JSON.stringify({
      browser: [],
      countries: [],
      languages: [],
      accessibility: [],
    }),
  );
  return formData;
};
