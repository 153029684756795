export const Routes = {
  current: "",
  default: "/",
  home: "home",
  project: "projects",
  projectDetails: "project-details",
  login: "login",
  registration: "registration",
  emailVerification: "email-verification",
  accountDetails: "account-details",
  resetPassword: "reset-password",
  createNewPassword: "create-new-password",
  settings: "settings",
  help: "help",
  figmaAuth: "figma-auth",
  mergeScreens: "projects/:id/merge-screens",
  kanban: "projects/:projectId/board",
  backlog: "projects/:projectId/backlog",
  organizationSettings: "organization-settings",
  notFound: "404",
};
