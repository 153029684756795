import { API } from "api/apiURLs";
import { customFetcher } from "api/fetcher";

const fetcherApplicationJson = customFetcher("application/json");
const fetcherMultipartData = customFetcher("multipart/form-data");

export const getProjectDetails = async (id: number, workspace: number, options?: any) => {
  const res = await fetcherApplicationJson.get(API.getProjectDetails(id), {
    headers: { workspace },
    ...options,
  });
  return res;
};

export const getProjectPageImportStatus = async (projectId: number) => {
  const res = await fetcherApplicationJson.get<{ task_id: string | null }>(
    API.getProjectFigmaImportStatus(projectId),
  );
  return res;
};

export const getTaskStatus = async (data: { task_id: string }, options?: any) => {
  const res = await fetcherApplicationJson.post(API.getTaskStatus(), data, options);
  return res;
};

export const uploadScreens = async (id: number, data?: any) => {
  const res = fetcherMultipartData.post(API.uploadScreens(id), data);
  return res;
};

export const createScreen = async (
  id: number,
  data: {
    name: string;
    description?: string;
    no_design: boolean;
    assignee?: string | null;
    estimation: number | null;
    priority: string;
  },
) => {
  const res = await fetcherApplicationJson.post(API.createScreen(id), data);
  return res;
};
export const createGroup = async (id: number, data: any) => {
  const res = await fetcherApplicationJson.post(API.createGroup(id), data);
  return res;
};

export const moveItems = async (
  id: number,
  workspace: number,
  data: {
    destination_order: number | null;
    destination_group?: number | null;
    screen_ids?: number[] | [];
    group_ids?: number[] | [];
  },
) => {
  const res = await fetcherApplicationJson.post(API.moveItems(id), data, {
    headers: { workspace },
  });
  return res;
};

export const deleteScreen = async (id: number) => {
  const res = await fetcherApplicationJson.delete(API.deleteScreen(id));
  return res;
};

export const deleteGroup = async (id: number) => {
  const res = await fetcherApplicationJson.delete(API.deleteGroup(id));
  return res;
};
export const deleteElement = async (id: number) => {
  const res = await fetcherApplicationJson.delete(API.deleteElement(id));
  return res;
};

export const updateGroup = async (slug: string, data: any) => {
  const res = await fetcherMultipartData.patch(API.updateGroup(slug), data);
  return res;
};
export const updateScreen = async (slug: string, data: any) => {
  const res = await fetcherMultipartData.patch(API.updateScreen(slug), data);
  return res;
};
export const updateElement = async (slug: string, data: any) => {
  const res = await fetcherMultipartData.patch(API.updateElement(slug), data);
  return res;
};

export const markElement = async (
  slug: string,
  data: {
    description: string;
    name: string;
    estimation: number | null;
    priority: string;
    assignee: string | null;
    coordinates: number[][];
  },
) => {
  const res = await fetcherApplicationJson.post(API.markElement(slug), data);
  return res;
};
export const createIssue = async (slug: string, data: any) => {
  const res = await fetcherApplicationJson.post(API.markElement(slug), data);
  return res;
};

export const duplicate = async (id: number, data: any) => {
  const res = await fetcherApplicationJson.post(API.duplicateItems(id), data);
  return res;
};

export const multipleDelete = async (id: number, data: string[]) => {
  const res = await fetcherApplicationJson.post(API.deleteMultiple(id), { slugs: data });
  return res;
};

export const mergeScreens = async (id: number, data: { merge_data: number[][] }) => {
  const res = await fetcherApplicationJson.post(API.mergeScreens(id), data);
  return res;
};

export const getFigmaFilePages = async (data: { file_link: string }) => {
  const res = await fetcherApplicationJson.post(API.getFigmaFilePages(), data);
  return res;
};

export const getFigmaImages = async (data: { file_link: string; page_node_ids: string[] }) => {
  const res = await fetcherApplicationJson.post(API.getFigmaImages(), data);
  return res;
};
export const exportPDF = async (id: number, workspace: number) => {
  const res = await fetcherApplicationJson.post(
    API.exportPDF(id),
    { type: "pdf" },
    { headers: { workspace } },
  );
  return res;
};

export const exportTimeLog = async (id: number) => {
  const res = await fetcherApplicationJson.get(API.exportTimeLog(id));
  return res;
};
export const exportJiraCsv = async (id: number) => {
  const res = await fetcherApplicationJson.post(API.exportJiraCsv(), { project: id });
  return res;
};

export const importScreensFigma = async (
  id: number,
  data: {
    file_link: string;
    direct: boolean;
    images: { [key: string]: { name: string; link: string } };
    structure: any[];
  },
) => {
  const res = await fetcherApplicationJson.post(API.importScreensFigma(id), data);
  return res;
};

export const logTime = async (
  slug: string,
  type: string,
  data: { seconds: number; date: string; description: any },
) => {
  const itemUpdateFunc: { [key: string]: (slug: string) => string } = {
    group: API.groupLogTime,
    screen: API.screenLogTime,
    element: API.elementLogTime,
  };
  let logDate = new Date(Date.now()).toISOString();
  if (data.date) {
    logDate = new Date(data.date).toISOString();
  }
  const res = await fetcherApplicationJson.post(itemUpdateFunc[type](slug), {
    log_time: data.seconds,
    log_date: logDate ?? null,
    comment: data.description,
  });
  return res;
};

export const approve = async (projectId: number, data: { screen_ids: number[] }) => {
  const res = await fetcherApplicationJson.post(API.readScreenUpdate(projectId), data);
  return res;
};

export const generateFullProject = async (projectId: number) => {
  const domain = process.env.REACT_APP_GENERATION_SERVICE_DOMAIN || "";
  const res = await fetcherApplicationJson.post(API.generateFullProject(domain, projectId));
  return res;
};
