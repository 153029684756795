import { createSlice } from "@reduxjs/toolkit";

export interface ReloadModalProps {
  unsavedChanges: boolean;
}

const initialState: ReloadModalProps = {
  unsavedChanges: false,
};

export const reloadModalSlice = createSlice({
  name: "reloadModal",
  initialState,
  reducers: {
    setUnsavedChanges: (state, { payload }) => {
      state.unsavedChanges = payload;
    },
  },
});

export const { setUnsavedChanges } = reloadModalSlice.actions;

export default reloadModalSlice.reducer;
