import { API } from "api/apiURLs";
import { customFetcher } from "api/fetcher";

const fetcherApplicationJson = customFetcher("application/json");
const fetcherFormData = customFetcher("multipart/form-data");

const getRequirements = async (id: number) => {
  const res = await fetcherApplicationJson.get(API.requirementsAPI(id));
  return res;
};

const postRequirements = async (data: any) => {
  const res = await fetcherFormData.post(API.postRequirementsAPI(), data);
  return res;
};
const updateRequirements = async (id: number, data: any) => {
  const res = await fetcherFormData.patch(API.requirementsAPI(id), data);
  return res;
};
const deleteRequirements = async (id: number) => {
  const res = await fetcherApplicationJson.delete(API.requirementsAPI(id));
  return res;
};
const deleteFile = async (projectId: number, attachmentId: number) => {
  const res = await fetcherApplicationJson.delete(API.deleteNFCFile(projectId, attachmentId));
  return res;
};

export { getRequirements, postRequirements, updateRequirements, deleteRequirements, deleteFile };
