import { Badge, Button, Loader, MantineThemeOverride, createTheme } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import datePickerStyles from "./datePickerStyles.module.css";
import buttonStyles from "./buttonStyles.module.css";
import badgeStyles from "./badgeStyles.module.css";

export const theme: MantineThemeOverride = createTheme({
  fontFamily: "Inter,sans-serif",
  // fontFamily: "Inter, Inter-Regular, Inter-Medium, Inter-SemiBold, Inter-Bold, sans-serif",
  headings: {
    sizes: {
      h1: { fontSize: "30px", lineHeight: "45px" },
      h2: { fontSize: "24px", lineHeight: "34px" },
    },
  },
  components: {
    Button: Button.extend({ classNames: buttonStyles }),
    DatePickerInput: DatePickerInput.extend({ classNames: datePickerStyles }),
    Loader: Loader.extend({ defaultProps: { color: "violet" } }),
    Badge: Badge.extend({ classNames: badgeStyles }),
  },
});

export const COLORS = {
  mainPrimary: "#4040C9",
  lightPrimary: "#4A49CB",
  mainText: "#08081E",
  mainSecondary: "#F1F1FB",
  hoverBlue: "#3232B1",
  hoverTurquoise: "#53B1FD",
  grayNavbarBackground: "#FDFDFD",
  grayNavbarDarkBackground: "#f7f8f9",
  grayMainBackground: "#F9FAFB",
  grayMain: "#667085",
  lightGray: "#EAECF0",
  mainBlack: "#000000",
  mainWhite: "#FFFFFF",
  lightRed: "#FEF3F2",
  hoverRed: "#FEE4E2",
  mainRed: "#B42318",
  errorRed: "#FF0000",
  backgroundSecondaryColor: "#E9ECEF",
  midnightBlue: "#344054",
  warningYellow: "#DC6803",
  backgroundYellow: "#F9E6D8",
};
